<template>
  <div>
    <div class="goods">
      <van-image :src="product.image" alt=""/>
<!--      <van-image :src="require('@/assets/images/6.jpg')" alt=""/>-->

      <div class="goodsInfo">
        <div class="goodsName van-multi-ellipsis--l2">
          <span class="ys" v-show="product.libckflag ===0">预售</span>
          <span class="xh" v-show="product.libckflag ===1">现货</span>
          {{ product.name }}
        </div>


        <div class="handle">
          <div class="price">
            <span>¥</span>
            <span>{{ product.price }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="hr"></div>


    <div class="bottom">
      <div class="item">
        <img src="./../../assets/images/goods/buyMustSee/bkts.png" alt="">

        <div class="list">
          <p>
            <img src="./../../assets/images/goods/buyMustSee/tips.png" alt="">
            <span class="key">配送方式：</span>
            <span class="value" v-show="product.deliveryMode ==='2'">配送到校</span>
            <span class="value" v-show="product.deliveryMode ==='1'">快递到家</span>
          </p>
          <p v-show="product.deliverGoodsTemp === '1'">
            <img src="./../../assets/images/goods/buyMustSee/tips.png" alt="">
            <span class="key">下单<span class="value" v-text="product.day"/>天内发货：</span>
          </p>
          <p v-show="product.deliverGoodsTemp === '2'">
            <img src="./../../assets/images/goods/buyMustSee/tips.png" alt="">
            <span class="key">预计配送时间：</span>
            <span class="value">{{ product.deliverGoodsDate }}</span>
          </p>
          <p v-show="product.deliverGoodsTemp === '3'">
            <span class="value" v-html="product.deliverGoodsContent"></span>
          </p>
          <p>
            <img src="./../../assets/images/goods/buyMustSee/tips.png" alt="">
            <span class="key">快递费用：</span>
            <span v-if="product.deliveryMode === '2'" class="value">0元</span>

            <span v-if="null != deliverySet && product.deliveryMode === '1'" class="value">首{{deliverySet.startStandards}}件，邮费{{deliverySet.startFees}}元，
            每续{{deliverySet.addStandards}}件，邮费加{{deliverySet.addFees}}元，
            {{ null != deliverySet.freeShippingNum && undefined !== deliverySet.freeShippingNum?"件数达到"+deliverySet.freeShippingNum+"件包邮，":""}}
            {{ null != deliverySet.capping && undefined !== deliverySet.capping ?"邮费封顶"+deliverySet.capping+"元":""}}</span>
          </p>
        </div>
      </div>


      <div class="item">
        <img src="./../../assets/images/goods/buyMustSee/wxts.png" alt="">

        <div class="tips" v-html="product.mainmemo">
<!--          亲爱的家长朋友，请您一定了解清楚以上重要信息，根据实际需求购买。-->
        </div>
      </div>

    </div>
    <div class="btn">
      <van-button :type="seconds > 0 ? 'info' : 'primary'" block :color="seconds > 0 ? '#dcdcdc':'#07c160'" :disabled="seconds > 0" @click="goToDetail">
        {{ seconds > 0 ? `请仔细阅读购买必看提示（0${seconds}s）` : '下一步' }}
      </van-button>
    </div>

  </div>
</template>

<script>

export default {
  name: "BuyMustSee",
  data() {
    return {
      timer: null,
      seconds: 5,
      product:{},
      deliverySet:{},
      show:false
    }
  },

  methods: {
    goToDetail() {
      this.$router.replace(`/goodsDetail/${this.product.id}`);
    },
    queryProduct(id){
      this.$http.get(`/product/h5_product/queryProductInfo/${id}`).then(res => {
        this.deliverySet = res.data.deliverySet;
        this.product = res.data.product;
      });
    }
  },

  activated() {
    Object.assign(this.$data, this.$options.data())
    this.queryProduct(this.$route.query.product.id);
    this.timer = setInterval(() => {
      this.seconds = this.seconds - 1;

      if (this.seconds === 0) {
        clearInterval(this.timer)
      }
    }, 1000)
  }
}

</script>
<style lang="less" scoped>
.hr {
  height: 10px;
  background-color: #f4f4f4;
}

.goods {
  padding: 10px 47px 10px 18px;
  display: flex;

  .van-image {
    height: 92px;
    width: 110px;
    border-radius: 5px;
    margin-right: 34px;
  }
}

.goodsInfo {
  .goodsName {
    font-size: 12px;
    color: #333333;
    line-height: 17px;
    margin-top: 3px;
    margin-bottom: 15px;

    .ys {
      width: 35px;
      height: 14px;
      background: linear-gradient(264deg, #f58130, #ef2514);
      background: linear-gradient(264deg, #fa0606, #ee3838);
      border-radius: 2px;

      font-size: 12px;
      font-weight: 500;
      color: #F4F4F4;

      text-align: center;

      line-height: 14px;
      display: inline-block;
      margin-right: 3px;

    }

    .xh {
      width: 32px;
      background: linear-gradient(264deg, #4db3ee, #00A0E9);
      border-radius: 2px;

      font-size: 12px;
      font-weight: 500;
      color: #F4F4F4;
      line-height: 12px;
      text-align: center;
      padding: 2px 0;
      display: inline-block;
      margin-right: 3px;

    }
  }


  .handle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 27px;

    .price {
      color: #D53A26;
      font-weight: 500;

      span:first-child {
        font-size: 12px;
        font-weight: bold;
      }

      span:last-child {
        font-size: 18px;
        font-weight: bold;
      }
    }

  }
}

.bottom {
  padding: 14px 18px;

  .item {
    > img {
      width: 340px;
      height: 36px;
    }

    .tips {
      font-size: 12px;
      color: #999;
      line-height: 17px;
      margin-top: 7px;
    }

    .list {
      padding-bottom: 31px;

      p {
        margin-top: 16px;
        line-height: 12px;

        img {
          height: 12px;
          width: 12px;
          margin-right: 7px;
        }

        span {
          font-size: 12px;

          &.key {
            color: #999;
          }

          &.value {
            color: #212121;
          }
        }
      }
    }
  }
}

.btn {
  position: absolute;
  bottom: 27px;
  left: 18px;
  right: 18px;
}

::v-deep .van-button--disabled {
  opacity: 1 !important;
}
</style>
